import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button, DatePicker, Radio, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BranchAPI from '../../../API/BranchAPI';
import { getWindowTitle } from '../../../App/variables';
import AppCommandBar from '../../../Components/AppCommandBar/AppCommandBar';
import PrintOrders from '../../../Components/PrintPackingSlip/PrintOrders';
import PrintPackingSlip from '../../../Components/PrintPackingSlip/PrintPackingSlip';
import { filialer } from '../../../Constants/Filialer';
import { OrderStatusList, SubOrderStatusList } from '../../../Constants/OrderStatus';
import { AppException } from '../../../Models/Exceptions';
import { Order } from '../../../Types/ResponseTypes/Order';
import { cols } from './Components/columns';
import OrderTab from './Components/OrderTab';
import './OrdersPage.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

enum OrderTypeFilter {
  Orders = 1,
  SubOrders
}

interface IOrdersPage {
  selectedFilial: number,
  setFilial: (val: number) => void,
};


const OrdersPage: React.FunctionComponent<IOrdersPage> = ({ selectedFilial, setFilial }) => {
  const [error, setError] = useState<AppException>();

  const [orders, setOrders] = useState<Order[]>([]);
  const [subOrders, setSubOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [printing, setPrinting] = useState<boolean>(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { filialId } = useParams<{ filialId?: string }>();

  const statusCodeParam = searchParams.get('statusCode') ?? '00';
  const [statusCode, setStatusCode] = useState<string>(statusCodeParam);
  const [dateFilter, setDateFilter] = useState<string>(moment().format("yyMMDD").substring(2, 8));
  const orderTypeParam = searchParams.get('orderType') ?? '2';
  const [orderType, setOrderType] = useState<OrderTypeFilter>(+orderTypeParam);

  const filterDescriptions = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = getWindowTitle(`Ordrar - ${filialId} (${dateFilter}, ${statusCode})`)
  }, [filialId, filterDescriptions])

  useEffect(() => {
    if (filialId && loading) {
      if (orderType == OrderTypeFilter.Orders)
        BranchAPI.getOrdersByFilial(+filialId, dateFilter ? `date=${dateFilter}` : '')
          .then(_ => {
            setLoading(false);
            setOrders(_.result);
          })
          .catch(_ => setError(_));
      else if (orderType == OrderTypeFilter.SubOrders)
        BranchAPI.getSubOrdersByFilial(+filialId, dateFilter ? `date=${dateFilter}` : '')
          .then(_ => {
            setLoading(false);
            setSubOrders(_.result);
          })
          .catch(_ => setError(_));
    }
  }, [loading])

  useEffect(() => {
    if (!loading) setLoading(true);
  }, [filialId, dateFilter, orderType]);

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: `?statusCode=${statusCode}&dateFilter=${dateFilter}&orderType=${orderType}`
    });
  }, [statusCode, dateFilter, orderType]);

  if (loading) {
    <div className="app-page-content">
      <Spin tip="Laddar ordrar.." />
    </div>
  }

  useEffect(() => {
    if (printing && selectedRowKeys && selectedRowKeys.length > 0) {
      BranchAPI.getOrdersByIds(+filialId!, selectedRowKeys.map(_ => +_))
        .then(_ => {
          const tab = window.open("", "_blank");
          tab?.document.write("<div id='root'></div>");
          const rootEle = tab?.document.getElementById("root")

          if (rootEle) {
            const root = ReactDOMClient.createRoot(rootEle);
            root.render(<PrintPackingSlip orders={_.result} />);
          }

          setPrinting(false);
        })
        .catch(_ => {
          setPrinting(false);
          setError(_);
        });
    }
    else if (printing) setPrinting(false);
  }, [printing]);

  const printPackingSlip = () => {
    if (selectedRowKeys) {
      setPrinting(true);

    }
  };

  const printOrders = () => {
    const tab = window.open("", "_blank");
    tab?.document.write("<div id='root'></div>");
    const rootEle = tab?.document.getElementById("root")

    if (rootEle) {
      const root = ReactDOMClient.createRoot(rootEle);
      root.render(<PrintOrders orders={orderType == OrderTypeFilter.Orders ? orders : subOrders} />);
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="app-page-content">
      <AppCommandBar
        actions={[
          { text: "Plocksedel", onClick: printPackingSlip, icon: faPrint, disabled: (!hasSelected || printing) },
          { text: "Order Lista", onClick: printOrders, icon: faPrint, disabled: (orderType == OrderTypeFilter.Orders ? orders : subOrders).length == 0 },
        ]}
        childrenRight={[
          <Radio.Group value={orderType} onChange={e => setOrderType(e.target.value)} buttonStyle="solid">
            <Radio.Button value={OrderTypeFilter.Orders}>Huvudordrar</Radio.Button>
            <Radio.Button value={OrderTypeFilter.SubOrders}>Subordrar</Radio.Button>
          </Radio.Group>,
          <Tooltip title="Leverans Datum">
            <DatePicker
              defaultValue={moment()}
              onChange={(date) => setDateFilter(date?.format("yyMMDD").substring(2, 8) ?? '')}
            />
          </Tooltip>,

          <Tooltip title="Levererande Filial">
            <Select
              showSearch
              value={selectedFilial}
              style={{ width: 100 }}
              placeholder="Välj levererande filial.."
              onSelect={(val: number) => setFilial(val)}
            >
              {
                filialer.map(_ => (<Option value={_}>{_}</Option>))
              }
            </Select>
          </Tooltip>,
        ]}
      />

      <div id="app-orders-tab-nav" className="app-padded-content no-print">
        {
          (orderType == OrderTypeFilter.Orders ? OrderStatusList : SubOrderStatusList).map(_ => (
            <Button
              type="text"
              className={`app-orders-tab-nav-link ${statusCodeParam == _.statusCode ? 'active' : ''}`}
              onClick={() => setStatusCode(_.statusCode ?? '')}
            >
              {_.displayName}
            </Button>
          ))
        }
      </div>
      <div id="app-orders" className="app-padded-content">
        <Spin spinning={loading}>
          <OrderTab
            columns={cols}
            orders={(orderType == OrderTypeFilter.Orders ? orders : subOrders).filter(_ => _.hustyp == statusCodeParam)}
            displayName={OrderStatusList.find(_ => _.statusCode == statusCodeParam)?.displayName ?? 'Ordrar'}
            rowSelection={rowSelection}
          />
        </Spin>
      </div>


    </div >
  );
};

export default OrdersPage;
