import { mockOrder, mockOrders } from '../MockData/mock-order';
import { httpRequestWithMock } from '../Services/HttpService';
import { Order } from '../Types/ResponseTypes/Order';

const BranchAPI = {
  getOrdersByFilial: (filialId: number, query?: string) => httpRequestWithMock<Order[]>('GET', `/v1/filialer/${filialId}/orders${query ? `?${query}`: ''}`, mockOrders(50)),
  getSubOrdersByFilial: (filialId: number, query?: string) => httpRequestWithMock<Order[]>('GET', `/v1/filialer/${filialId}/suborders${query ? `?${query}`: ''}`, mockOrders(50)),
  getOrderById: (filialId: number, id: string) => httpRequestWithMock<Order>('GET', `/v1/filialer/${filialId}/orders/${id}`, mockOrder),
  getOrdersByIds: (filialId: number, ids: number[]) => httpRequestWithMock<Order[]>('GET', `/v1/filialer/${filialId}/orders/full?${ids.map(_ => `orderIds=${_}`).join('&')}`, [mockOrder]),
};

export default BranchAPI;
