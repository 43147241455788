export interface AppLinks {
  path: string,
  name: string,
  key: string,
  active: boolean,
};

export const getLinks = (filialId: number): AppLinks[] => [
  {
    path: '/',
    name: 'Start',
    key: 'start',
    active: false,
  },
  {
    path: `/filialer/${filialId}/orders`,
    key: 'orders',
    name: 'Beställningar',
    active: false,
  },
];
