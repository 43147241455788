import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { getWindowTitle } from '../../App/variables';
import IPage from '../../Interfaces/IPage';
import { AppException } from '../../Models/Exceptions';
import OrderPage from './OrderPage/OrderPage';
import OrdersPage from './OrdersPage/OrdersPage';

interface IFilialPage extends IPage {

};

const FilialPage: React.FunctionComponent<IFilialPage> = () => {
  const [error, setError] = useState<AppException>();
  const { filialId } = useParams<{ filialId?: string }>();
  const [selectedFilial, setSelectedFilial] = useState(+(filialId ? filialId: 1));
  const navigate = useNavigate();


  
  useEffect(() => {
    if (!filialId || selectedFilial != +filialId) {
      localStorage.setItem('filialid', selectedFilial.toString());
      navigate(location.pathname.replace(`filialer/${filialId}`, `filialer/${selectedFilial}`))
    };
    
  }, [selectedFilial]);

 

  const setFilial = (value: number) => setSelectedFilial(value);
  return (
    <div className="app-page-content">
      
      <Routes>
      
        <Route
          path={`/orders/:orderId`}
          element={<OrderPage />}
        />
      
      {
        filialId ? (<Route
          path={`/orders`}
          element={<OrdersPage selectedFilial={+filialId} setFilial={setFilial}/>}
        />): null
      }
        
      </Routes>
    </div>
  );
};

export default FilialPage;
