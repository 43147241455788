

import { AccessTokenExpired, AppException, BadRequest, ForbiddenRequest, ResourceNotFound, ServerError, UnknownError } from '../Models/Exceptions';
import RequestModel from '../Models/RequestModels/RequestModel';
import { ApiResponse } from '../Types/ApiResponse';
import AuthService from './AuthService';

const getBaseUrl = () => {
  if (window.location.origin.includes('localhost')) return 'https://localhost:5001/api';
  else if (window.location.origin.includes('-dev')) return 'https://url_to_dev_env/api';
  else if (window.location.origin.includes('-test')) return 'https://beijer-int-and-mid-trygg-ods-api-test.azurewebsites.net/api';
  else return 'https://beijer-int-and-mid-trygg-ods-api-prod.azurewebsites.net/api';
}

export const generateException = (httpStatus: number, errorBody: string): AppException => {
  switch (httpStatus) {
    case 400:
      return new BadRequest(errorBody);
    case 401:
      return new AccessTokenExpired(errorBody);
    case 403:
      return new ForbiddenRequest(errorBody);
    case 404:
      return new ResourceNotFound(errorBody);
    case 500:
      return new ServerError(errorBody);
    default:
      return new UnknownError(errorBody);
  }
};

export const getQueryParams = (params: [{ key: string, val: any }]) => {
  return params && params.length > 0
    ? params.map(_ => _.val !== null && _.val !== undefined && _.val !== 'undefined' ? (`${_.key}=${_.val}`) : null).filter(_ => _ !== null).join('&')
    : '';
};


export const httpRequestWithMock = async <T>(method: string, endpoint: string, mockData: T, payload?: RequestModel): Promise<ApiResponse<T>> => {
  if (window.location.search.includes("useMockData=true") && mockData) return {
    errorType: null,
    hasNext: null,
    isError: null,
    message: "OK",
    nextLink: null,
    responseException: null,
    result: mockData,
    statusCode: 200,
    version: 'null'
  };

  return httpRequestBase(method, endpoint, payload);
}

export const httpRequest = async <T>(method: string, endpoint: string, payload?: RequestModel): Promise<ApiResponse<T>> => {
  return httpRequestBase(method, endpoint, payload);
}

const httpRequestBase = async <T>(method: string, endpoint: string, payload?: RequestModel): Promise<ApiResponse<T>> => {

  var response = await fetch(`${getBaseUrl()}${endpoint}`, {
    method,
    headers: {
      'credentials': 'same-origin',
      'Content-Type': 'application/json; charset=utf-8',
      ...(await AuthService.getCurrent().getAuthHeaders()),
    },
    body: payload ? JSON.stringify(payload) : null,
  });

  if (response.ok)
    return response.headers.get("Content-Type")?.includes('application/json')
      ? response.json()
      : response.text();
  else return response.text().then((errorBody) => {
    throw generateException(response.status, errorBody);
  });
};

export const postFileRequest = async <T>(endpoint: string, file: File): Promise<T> => {
  var data = new FormData();
  data.append('file', file);

  var response = await fetch(`${getBaseUrl()}${endpoint}`, {
    method: 'POST',
    headers: {
      'credentials': 'same-origin',
      ...(await AuthService.getCurrent().getAuthHeaders()),
    },
    body: data,
  });

  if (response.ok)
    return response.headers.get("Content-Type")?.includes('application/json')
      ? response.json()
      : response.text();
  else return response.text().then((errorBody) => {
    throw generateException(response.status, errorBody);
  });
};
