import React from 'react';
import { Link } from 'react-router-dom';
import { IDictionary } from '../../../../Interfaces/IDictionary';
import { ColumnsType } from 'antd/lib/table';
import { OrderStatusList } from '../../../../Constants/OrderStatus';
import { Order } from '../../../../Types/ResponseTypes/Order';
import { deliveryMethodMap } from '../../../../Constants/PrintValueMaps';

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const addTrailingZeros = (x: number, totalLength: number) => {
  return String(x).padEnd(totalLength, '0');
}

export const cols: ColumnsType<Order> = [
  {
    title: 'Order Id',
    dataIndex: 'huorN9',
    sorter: (a, b) => a.huorN9 && b.huorN9
      ? -a.huorN9.toString().localeCompare(b.huorN9.toString())
      : 0,
    render: (val, item) => (
      <Link to={`${val}`}>{val}</Link>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'hustyp',
    sorter: (a, b) => a.hustyp && b.hustyp
      ? -a.hustyp.toString().localeCompare(b.hustyp.toString())
      : 0,
  },
  {
    title: 'Typ',
    dataIndex: 'huotyp',
    sorter: (a, b) => a.huotyp && b.huotyp
      ? -a.huotyp.toString().localeCompare(b.huotyp.toString())
      : 0,
  },
  {
    title: 'Leveranstidpunkt',
    dataIndex: 'hulklo',
    sorter: (a, b) => a.hulklo && b.hulklo
      ? -a.hulklo.toString().localeCompare(b.hulklo.toString())
      : 0,
  },
  {
    title: 'Önskad Leveransdag',
    dataIndex: 'hultid',
    sorter: (a, b) => a.hultid && b.hultid
      ? -a.hultid.toString().localeCompare(b.hultid.toString())
      : 0,
  },
  
  {
    title: 'Lev. Sätt',
    dataIndex: 'hulsät',
    render: (val) => (deliveryMethodMap[val]),
    sorter: (a, b) => {
        if (a.hulsät && b.hulsät)
          return b.hulsät.toString().localeCompare(a.hulsät.toString())
        else if (a.hulsät && !b.hulsät) return 1;
        else if (!a.hulsät && b.hulsät) return -1;
        return 0;
    }
  },
  {
    title: 'Arbetsp. Namn',
    dataIndex: 'hunamn',
    sorter: (a, b) => {
        if (a.hunamn && b.hunamn)
          return b.hunamn.toString().localeCompare(a.hunamn.toString())
        else if (a.hunamn && !b.hunamn) return 1;
        else if (!a.hunamn && b.hunamn) return -1;
        return 0;
    }
  },
  
  {
    title: 'Adress',
    dataIndex: 'huadR1',
    sorter: (a, b) => {
      if (a.huadR1 && b.huadR1)
        return b.huadR1.toString().localeCompare(a.huadR1.toString())
      else if (a.huadR1 && !b.huadR1) return 1;
      else if (!a.huadR1 && b.huadR1) return -1;
      return 0;
    }
  },
  
  {
    title: 'Adress',
    dataIndex: 'huadR2',
    sorter: (a, b) => {
      if (a.huadR2 && b.huadR2)
        return b.huadR2.toString().localeCompare(a.huadR2.toString())
      else if (a.huadR2 && !b.huadR2) return 1;
      else if (!a.huadR2 && b.huadR2) return -1;
      return 0;
    }
  },
  {
    title: 'Post Ort',
    dataIndex: 'hupadr',
    sorter: (a, b) => a.hupadr && b.hupadr
      ? -a.hupadr.toString().localeCompare(b.hupadr.toString())
      : 0,
  },
  
  {
    title: 'Vikt',
    dataIndex: 'hutvik',
    align: 'right',
    sorter: (a, b) => a.hutvik - b.hutvik,
    render: (val) => {
      if (!val) return '';
      const parts = val?.toString()?.split('.')
      return `${numberWithCommas(parts[0])}.${addTrailingZeros(parts[1] ?? 0,3)}`;
    }
  },
];

