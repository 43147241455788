import { Input, Select } from 'antd';
import { Account } from 'msal';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import OrderAPI from '../../API/OrderAPI';
import { AppLinks } from '../../App/links';
import { getWindowTitle } from '../../App/variables';
import { SelectedFilialAtom } from '../../Atoms/SelectedFilialAtom';
import './AppHeader.scss';

const { Search } = Input;
const { Option } = Select;


interface IAppHeader {
  navigationLinks: AppLinks[],
  account: Account,
};

const AppHeader: React.FC<IAppHeader> = ({ navigationLinks, account }): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);


  useEffect(() => {
    if (searching) {
      OrderAPI.getOrderByOrderId(searchTerm)
        .then(_ => {
          navigate(`/filialer/${_.result.hufili}/orders/${_.result.huorN9}${window.location.search.includes("useMockData=true") ? '?useMockData=true' : ''}`);
          setSearchTerm('');
          setSearching(false);
        })
        .catch(_ => {
          navigate(`/not-found${window.location.search.includes("useMockData=true") ? '?useMockData=true' : ''}`, { state: { searchTerm: searchTerm } });
          setSearching(false);
        })
    }
  }, [searching])


  const onSearch = () => {
    setSearching(true);
  };

  return (
    <div id="app-header" className="no-print">
      <div id="app-header-top">
        <div id="app-header-logo">
          <a href={window.location.origin}>
            <img src="/assets/img/beijer-logo.svg" alt="logo" id="app-logo" />
          </a>
        </div>
        <div id="app-header-title">{getWindowTitle()}</div>
        <div id="app-header-user">
          <p>{account ? account.name : ''}</p>
          <p>{account ? account.userName : ''}</p>
        </div>
      </div>
      <div id="app-header-bottom">
        <div id="app-header-nav">
          {
            navigationLinks.map(link => (
              <Link
                to={link.path}
                key={link.name}
                data-linkname={link.name}
                className={'app-main-navigation-link '.concat((location.pathname.split('/')[1].toLowerCase() === link.key.toLowerCase() || (link.name === 'START' && location.pathname === '/')) ? 'app-link-active' : '')}
              >
                {link.name}
              </Link>
            ))
          }
        </div>
        <div id="app-header-searchbar">
          <Search loading={searching} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="123-123456789" style={{ width: '260px', float: 'right', margin: '9px 20px' }} onSearch={onSearch} />
        </div>
        <div id="app-header-selector-wrapper">
          <div id="app-header-selector">
          </div>

        </div>
      </div>

    </div>
  );
}

export default AppHeader;
