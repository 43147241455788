import {
  InfoCircleOutlined
} from '@ant-design/icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Collapse, Descriptions, Divider, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactElement, useEffect, useState } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { To, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import BranchAPI from '../../../API/BranchAPI';
import { getWindowTitle } from '../../../App/variables';
import { LanguageAtom } from '../../../Atoms/LanguageAtom';
import AppCommandBar from '../../../Components/AppCommandBar/AppCommandBar';
import PrintPackingSlip from '../../../Components/PrintPackingSlip/PrintPackingSlip';
import { orderFieldsDictionary } from '../../../Constants/OrderFields';
import { AppException } from '../../../Models/Exceptions';
import { Order } from '../../../Types/ResponseTypes/Order';
import { OrderRow } from '../../../Types/ResponseTypes/OrderRow';
import './OrderPage.scss';

const { Panel } = Collapse;
interface IOrderPage {
  ordersUrl?: string;
  filterDescription?: string;
};
export const cols: ColumnsType<OrderRow> = [
  {
    title: 'RARADN',
    dataIndex: 'raradn',
  },
  {
    title: 'RAART',
    dataIndex: 'raart',
  },
  {
    title: 'RABEN1',
    dataIndex: 'rabeN1',
  },
  {
    title: 'RABEN2',
    dataIndex: 'rabeN2',
  },
  {
    title: 'RAHYLL',
    dataIndex: 'rahyll',
  },
  {
    title: 'RAANTA',
    dataIndex: 'raanta',
  },
  {
    title: 'RAENH',
    dataIndex: 'raenh',
  }
];

const getFieldLabel = (fieldName: string): ReactElement => (
  <div>
    <span className="app-order-info-label">{orderFieldsDictionary[fieldName]}</span>
    <Tooltip title={fieldName} className="app-order-info-label-icon">
      <InfoCircleOutlined />
    </Tooltip>
  </div>
);

const OrderPage: React.FunctionComponent<IOrderPage> = ({ ordersUrl, filterDescription }) => {
  const [error, setError] = useState<AppException>();
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);
  const { filialId, orderId } = useParams<{ filialId?: string, orderId?: string }>();

  const navigate = useNavigate();
  const [language] = useRecoilState(LanguageAtom);

  useEffect(() => {
    document.title = getWindowTitle(`${orderId}`)
  }, [orderId])

  useEffect(() => {
    if (filialId && orderId && loading)
      BranchAPI.getOrderById(+filialId, orderId)
        .then(_ => {
          setLoading(false);
          setOrder(_.result);
        })
        .catch(_ => setError(_));

  }, [loading])
  useEffect(() => {
    if (!loading) setLoading(true);
  }, [filialId, orderId])


  if (loading) {
    <div className="app-page-content">
      <Spin tip={language.orderPage.loadingOrder} />
    </div>
  }

  if (!order) {
    <div>
      <p>{language.orderPage.headers.noOrderWithIdFound(orderId || '')}</p>
    </div>
  }

  const print = () => {
    if (order) {
      const tab = window.open("", "_blank");
      tab?.document.write("<div id='root'></div>");
      const rootEle = tab?.document.getElementById("root")

      if (rootEle) {
        const root = ReactDOMClient.createRoot(rootEle);
        root.render(<PrintPackingSlip orders={[order]} />);
      }
    }
  };

  const goToOrdersPage = () => navigate(ordersUrl ? ordersUrl : `/filialer/${filialId}/orders` as To, { replace: true });

  return (
    <>
      <AppCommandBar actions={[
        {
          text: "Plocksedel",
          onClick: print,
          icon: faPrint,
          disabled: orderId && (orderId.length != 9 || orderId.substring(7, 9) == '00') ? true : false,
        }
      ]} />

      <div id="app-order-header" className="app-padded-content">

        <Breadcrumb separator=">">
          <Breadcrumb.Item href='' onClick={goToOrdersPage}>Ordrar {filterDescription ?? ''}</Breadcrumb.Item>
          <Breadcrumb.Item>{orderId}</Breadcrumb.Item>
        </Breadcrumb>

        <h3>{orderId}</h3>
      </div>

      <div id="app-order-wrapper" className="app-padded-content">
        <div id="app-order-data">

          <Divider orientation="left">Kund information</Divider>
          <Spin tip="Laddar..." spinning={loading} >

            <Descriptions bordered column={4} size="small" >
              <Descriptions.Item span={2} label={getFieldLabel('HUADR1')}>{order?.huadR1}</Descriptions.Item>
              <Descriptions.Item span={2} label={getFieldLabel('HUADR2')}>{order?.huadR2}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPADR')}>{order?.hupadr}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPNR')}>{order?.hupnr}</Descriptions.Item>

              <Descriptions.Item label={getFieldLabel('HUREKV')}>{order?.hurekv}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUMOTT')}>{order?.humott}</Descriptions.Item>
              <Descriptions.Item span={2} label={getFieldLabel('HUMAIL')}>{order?.humail}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKONR')}>{order?.hukonr}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUUSER')}>{order?.huuser}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUTEL1')}>{order?.huteL1}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUTEL2')}>{order?.huteL2}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKNR')}>{order?.huknr}</Descriptions.Item>
            </Descriptions>
          </Spin>

          <Divider orientation="left">Leverans information</Divider>
          <Spin tip="Laddar..." spinning={loading} >
            <Descriptions bordered column={4} size="small">
              <Descriptions.Item label={getFieldLabel('HULDAG')}>{order?.huldag}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HULEVV')}>{order?.hulevv}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HULKLO')}>{order?.hulklo}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HULSÄT')}>{order?.hulsät}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HULTID')}>{order?.hultid}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HULVEK')}>{order?.hulvek}</Descriptions.Item>
              <Descriptions.Item span={1} label={getFieldLabel('HUPRIP')}>{order?.huprip}</Descriptions.Item>
              <Descriptions.Item span={1} label={getFieldLabel('HUTVIK')}>{order?.hutvik}</Descriptions.Item>
              <Descriptions.Item span={4} label={getFieldLabel('HUANVI')}>{order?.huanvi}</Descriptions.Item>
              <Descriptions.Item span={4} label={getFieldLabel('HUANV2')}>{order?.huanV2}</Descriptions.Item>
            </Descriptions>
          </Spin>

          <Divider orientation="left">Order information</Divider>
          <Spin tip="Laddar..." spinning={loading} >
            <Descriptions bordered column={4} size="small">
              <Descriptions.Item label={getFieldLabel('HUORN9')}>{order?.huorN9}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUSTYP')}>{order?.hustyp}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUDATU')}>{order?.hudatu}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUDATO')}>{order?.hudato}</Descriptions.Item>

              <Descriptions.Item label={getFieldLabel('HUFÖRE')}>{order?.huföre}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUFILI')}>{order?.hufili}</Descriptions.Item>

              <Descriptions.Item label={getFieldLabel('HUARB')}>{order?.huarb}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUNAMN')}>{order?.hunamn}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUGODS')}>{order?.hugods}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKLCS')}>{order?.huklcs}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKLOC')}>{order?.hukloc}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKPER')}>{order?.hukper}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKVNR')}>{order?.hukvnr}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUKVDA')}>{order?.hukvda}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUOBJE')}>{order?.huobje}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUOBJN')}>{order?.huobjn}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUOTYP')}>{order?.huotyp}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPBKD')}>{order?.hupbkd}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPLDA')}>{order?.huplda}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPLOC')}>{order?.huploc}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPLTI')}>{order?.huplti}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPRIO')}>{order?.huprio}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUPROJ')}>{order?.huproj}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HURDAT')}>{order?.hurdat}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HURKOD')}>{order?.hurkod}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUSÄLJ')}>{order?.husälj}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUSMS')}>{order?.husms}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUTAVG')}>{order?.hutavg}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUTELA')}>{order?.hutela}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUTIDK')}>{order?.hutidk}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUUSRÄ')}>{order?.huusrä}</Descriptions.Item>
              <Descriptions.Item label={getFieldLabel('HUUSRK')}>{order?.huusrk}</Descriptions.Item>
            </Descriptions>
          </Spin>


          <Divider orientation="left">Order rader</Divider>

          <Spin tip="Laddar..." spinning={loading} >
            <Table
              bordered
              columns={cols}
              rowKey={(_ ) => _.raradn}
              dataSource={order ? order.orderRows : []}
              size="small"
              pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['15', '20', '50', '100', '200'] }}
              sticky
              loading={loading}
            />
          </Spin>

        </div>



      </div>
    </>
  );
};

export default OrderPage;
