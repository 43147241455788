import { Order } from "../Types/ResponseTypes/Order";

export const mockOrder: Order = {


} as Order;

export const mockOrders = (numberOfOrders: number): Order[]  => {
    const data: Order[] = [];
    for (let i = 1; i < numberOfOrders; i++) {
        data.push(
            {
            } as Order
        );
    }

    return data;
};
