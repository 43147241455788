import { OrderTypes } from "./OrderTypes"

export type OrderStatus = {
    statusCode: string,
    displayName: string,
    orderType: OrderTypes
}


export const OrderStatusList: OrderStatus[] = [
    {
        statusCode: '00',
        displayName: 'Ej avslutad',
        orderType: OrderTypes.Order
    },
    {
        statusCode: '07',
        displayName: 'Helt levererad',
        orderType: OrderTypes.Order
    },
    {
        statusCode: '08',
        displayName: 'Helt fakturerad',
        orderType: OrderTypes.Order
    },
    {
        statusCode: '09',
        displayName: 'Makulerad',
        orderType: OrderTypes.Order
    },
]

export const SubOrderStatusList: OrderStatus[] = [
    {
        statusCode: '00',
        displayName: 'Ej avslutad',
        orderType: OrderTypes.Order
    },
    {
        statusCode: '02',
        displayName: 'Utskriven',
        orderType: OrderTypes.SubOrder
    },
    {
        statusCode: '03',
        displayName: 'Plockad',
        orderType: OrderTypes.SubOrder
    },
    {
        statusCode: '04',
        displayName: 'Fakturerad',
        orderType: OrderTypes.SubOrder
    },
    {
        statusCode: '05',
        displayName: 'Kontant betald',
        orderType: OrderTypes.SubOrder
    }
]