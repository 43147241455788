import { Select } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { filialer } from '../../Constants/Filialer';
import IPage from '../../Interfaces/IPage';
import { AppException } from '../../Models/Exceptions';

const { Option } = Select;

interface IFilialerPage extends IPage {

};

const FilialerPage: React.FunctionComponent<IFilialerPage> = () => {
  const [error, setError] = useState<AppException>();
  const navigate = useNavigate();

  if (localStorage.getItem('filialId')) {
    navigate(location.pathname.replace('filialer', `filialer/${localStorage.getItem('filialId')}/orders`))
  }

  return (
    <div className="app-page-content center">
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder="Välj levererande filial.."
        onSelect={(val: number) => navigate(location.pathname.replace('filialer', `filialer/${val}/orders`))}
      >
        {
          filialer.map(_ => (<Option value={_}>{_}</Option>))
        }
      </Select>
    </div>
  );
};

export default FilialerPage;
