import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import {
    RecoilRoot
} from 'recoil';
import App from './App/App';


const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);

root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);