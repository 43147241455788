import { Alert, Button, Input } from 'antd';
import React, { Children, ReactElement } from 'react';
import './AppCommandBar.scss';
import 'antd/dist/antd.min.css';
import { IAction } from '../../Interfaces/IAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons'



interface IAppCommandBar {
  actions: IAction[],
  childrenRight?: ReactElement[]
};

const AppCommandBarButton: React.FC<{action: IAction}> = ({ action}): ReactElement => {
  const { icon, text, onClick, disabled } = action;
  const btnClick = () => {
    if(!disabled) onClick();
  }

  return (
    <div
      className={`app-commandbar-button ${disabled ? 'disabled' : 'enabled'}`}
      onClick={btnClick}
    >
      <FontAwesomeIcon size="1x" icon={icon ? icon : faPlay} />
      <span>{text}</span>
    </div>
  )
};

const AppCommandBar: React.FC<IAppCommandBar> = ({ actions, childrenRight }): ReactElement => (
  <div className="app-commandbar no-print">
    {
      actions && actions.map((_, i) => {
        if (_.hide) return null;
        return (
          <AppCommandBarButton
            key={`acb-btn-${i}`}
            action = {_}
          />
        )
      })
    }

    <div className="app-commandbar-right">
      {childrenRight}
    </div>
  </div>
);

export default AppCommandBar;
