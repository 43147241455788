import { AuthenticationParameters, Configuration } from "msal";


const getAppClientId = () => {
    if (window.location.origin.includes('localhost')) return 'be62b561-d0e6-4384-96b4-d4292c084271';
    else if (window.location.origin.includes('-dev')) return 'dev_guid';
    else if (window.location.origin.includes('-test')) return 'be62b561-d0e6-4384-96b4-d4292c084271';
    else return '8f8cd8ee-70fc-462e-9c09-2f31768a12d0';
}


const getTryggOdsClientId = () => {
    if (window.location.origin.includes('localhost')) return '5de50e2f-af5d-4bac-8455-0ed93e0d4352';
    else if (window.location.origin.includes('-dev')) return 'dev_guid';
    else if (window.location.origin.includes('-test')) return '5de50e2f-af5d-4bac-8455-0ed93e0d4352';
    else return '9656e7b2-75fe-4f4d-9720-a4cb59df60aa';
}

export const scopes = {
    ApiOrdersRead: `api://${getTryggOdsClientId()}/Api.Orders.Read`,
    UserImpersonation: `api://be62b561-d0e6-4384-96b4-d4292c084271/user_impersonation`,
}


export const msalConfig: Configuration = {
    auth: {
      clientId: getAppClientId(),
      authority: "https://login.microsoftonline.com/2e114308-14ec-4d77-b610-490324fa1844",
      redirectUri: `${window.location.protocol}//${window.location.host}/`,
    }
  };


// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const tokenRequest: AuthenticationParameters = {
    scopes: [scopes.ApiOrdersRead]
};
