export const filialer: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    48,
    50,
    51,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    72,
    73,
    74,
    76,
    77,
    78,
    80,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    91,
    92,
    93,
    94,
    95,
    99,
    112,
    114,
    131,
    132,
    133,
    134,
    136,
    137,
    141,
    145,
    146,
    147,
    148,
    149,
    151,
    156,
    157,
    158,
    159,
    161,
    162,
    163,
    164,
    165,
    166,
    167,
    168,
    169,
    171,
    172,
    178,
    179,
    181,
    182,
    183,
    184,
    186,
    188,
    189,
    191
]