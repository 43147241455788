import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CompareFn, SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React from 'react';
import { Order } from '../../../../Types/ResponseTypes/Order';

interface IOrderTab {
  orders: Order[],
  displayName: string
  columns: ColumnsType<Order>,
  rowSelection: TableRowSelection<Order>,
};

const OrderTab: React.FunctionComponent<IOrderTab> = ({ orders, displayName, columns, rowSelection }) => {
    return (
      <div>
        <Table
          bordered
          title={() => `${displayName} - ${orders.length} ordrar`}
          rowKey={_ => _.huorN9}
          columns={columns}
          dataSource={orders}
          size="small"
          rowSelection={rowSelection}
          pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['15', '20', '50', '100', '200'] }}
          sticky
        />
      </div>
    );
}

export default OrderTab;
