import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactElement, useState } from 'react';
import { IDictionary } from '../../Interfaces/IDictionary';
import { Order } from '../../Types/ResponseTypes/Order';


interface IPrintOrders {
  orders: Order[],
};

const css = `
.app-print-orders {
    size: 17.78cm 23.495cm;
    margin: 0 16mm 0 16mm;
}

.app-print-orders-order p,
.app-print-orders-order span {
  font-size: 12px;
}


.app-print-orders-table table {
  width: 100%;
  padding: 5px 1cm;
}

.app-print-orders-table table thead th {
  
  text-align: left;
  font-size: 12px;
}

.app-print-orders-table table tr {
  border-bottom: 1px solid black;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.app-print-orders-table table tbody td {
  font-weight: 400;
  vertical-align: top;
  text-align: left;
  padding: 5px;
  font-size: 12px;
}

.app-print-orders-table table tbody td p {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 12px;
}

.app-print-orders-label { font-weight: 600; margin-right: 10px; }
.app-print-orders-value.missing {
  color: orange;
}
`;


export const sorter = (column: string, a: Order, b: Order): number => {
  if (column == 'huorN9') return a?.huorN9 && b?.huorN9 ? -a.huorN9.toString().localeCompare(b.huorN9.toString()) : 0;
  if (column == 'hustyp') return a.hustyp && b.hustyp ? -a.hustyp.toString().localeCompare(b.hustyp.toString()) : 0;
  if (column == 'huotyp') return a.huotyp && b.huotyp ? -a.huotyp.toString().localeCompare(b.huotyp.toString()) : 0;
  if (column == 'hulklo') return a.hulklo && b.hulklo ? -a.hulklo.toString().localeCompare(b.hulklo.toString()) : 0;
  if (column == 'hultid') return a.hultid && b.hultid ? -a.hultid.toString().localeCompare(b.hultid.toString()) : 0;
  if (column == 'hunamn') return a.hunamn && b.hunamn ? -a.hunamn.toString().localeCompare(b.hunamn.toString()) : 0;
  if (column == 'huadR1') {
    if (a.huadR1 && b.huadR1)
      return b.huadR1.toString().localeCompare(a.huadR1.toString())
    else if (a.huadR1 && !b.huadR1) return 1;
    else if (!a.huadR1 && b.huadR1) return -1;
    return 0;
  }
  if (column == 'huadR2') {
    if (a.huadR2 && b.huadR2)
      return b.huadR2.toString().localeCompare(a.huadR2.toString())
    else if (a.huadR2 && !b.huadR2) return 1;
    else if (!a.huadR2 && b.huadR2) return -1;
    return 0;
  }
  if (column == 'hupadr') return a.hupadr && b.hupadr ? -a.hupadr.toString().localeCompare(b.hupadr.toString()) : 0;
  if (column == 'hutvik') return a.hutvik - b.hutvik;
  return 0;
};



const PrintOrders: React.FC<IPrintOrders> = ({ orders }): ReactElement => {
  const [sortByColumn, setSortByColumn] = useState<string>('huorN9');
  return (
    <div className="app-print-orders">

<div className="app-print-orders-order">
    <style>{css}</style>

    <div className="app-print-orders-table">
      <table>
        <thead>
          <tr>
            <th onClick={() => setSortByColumn('huorN9')}>Order Id</th>
            <th onClick={() => setSortByColumn('hustyp')}>Status</th>
            <th onClick={() => setSortByColumn('huotyp')}>Typ</th>
            <th onClick={() => setSortByColumn('hulklo')}>Lev. Tidpunkt</th>
            <th onClick={() => setSortByColumn('hultid')}>Önskad Lev. dag</th>
            <th onClick={() => setSortByColumn('hunamn')}>Namn</th>
            <th onClick={() => setSortByColumn('huadR1')}>Adress 1</th>
            <th onClick={() => setSortByColumn('huadR2')}>Adress 2</th>
            <th onClick={() => setSortByColumn('hupadr')}>Post Ort</th>
            <th onClick={() => setSortByColumn('hutvik')}>Vikt</th>
          </tr>
        </thead>
        <tbody>
          {
            orders ? orders.sort((a, b) => a && b? sorter(sortByColumn, a, b) : 0).map((_, i) => (
              <tr key={`${_.huorN9}-or-${i}`}>
                <td>{_.huorN9}</td>
                <td>{_.hustyp}</td>
                <td>{_.huotyp}</td>
                <td>{_.hulklo}</td>
                <td>{_.hultid}</td>
                <td>{_.hunamn}</td>
                <td>{_.huadR1}</td>
                <td>{_.huadR2}</td>
                <td>{_.hupadr}</td>
                <td>{_.hutvik}</td>
              </tr>
            )) : null
          }
        </tbody>
      </table>
    </div>


  </div>
    </div>

  )
};

export default PrintOrders;
