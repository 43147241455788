import { IDictionary } from "../Interfaces/IDictionary";

export const orderFieldsDictionary: IDictionary<string> = {
    'HUABVB': 'Lev.tid arb.veckor eft.best',
    'HUADR1': 'Adress',
    'HUADR2': 'Adress',
    'HUAFIL': 'Filial',
    'HUAINK': 'Ordernummer 7 pos',
    'HUALOP': 'Löpnummer inköp',
    'HUANDI': 'Ansk/direkt för utskr. A/D/',
    'HUÄNDK': 'Ändringskod',
    'HUANTÄ': 'Antal ändringar',
    'HUANV2': 'Leveransavisering 2',
    'HUANVI': 'Lev.anvisning 1',
    'HUAOUT': 'Arbetsorder ut J/N',
    'HUARB': 'Arbetsplatsnummer',
    'HUAVID': 'Aviseringsdatum',
    'HUÄVII': 'Ändrad via inköp',
    'HUAVIS': 'Aviserat',
    'HUAVNR': 'Avtalsnummer',
    'HUAVTO': 'Ej avrundning ordertotal J/',
    'HUBEB1': 'Bet.sätt 1,kr',
    'HUBEB2': 'Bet.sätt 2,kr',
    'HUBEB3': 'Bet.sätt 3,kr',
    'HUBEBE': 'Belopp enl best.sätt',
    'HUBEFI': 'Betalt i filial',
    'HUBEID': 'Beteende-id',
    'HUBEKA': 'Ordern betald i kassan',
    'HUBEOR': 'Best.rab. beräkn. rad/order',
    'HUBER': 'Beställningsrab ex moms',
    'HUBERB': 'Ordervärdesrabatt',
    'HUBERJ': 'Beräkna avg. J/ Skaff/Dir',
    'HUBERM': 'Beställningsrab inkl moms',
    'HUBET1': 'Bet.sätt 1',
    'HUBET2': 'Bet.sätt 2',
    'HUBET3': 'Bet.sätt 3',
    'HUBETR': 'Betalningsreferens',
    'HUBETV': 'Betalningsvillkorskod',
    'HUBEUT': 'Skriva ut besällning',
    'HUBFT': 'Delad BFT  B/F/T/',
    'HUBH1': 'Bruttohöjning lager/direkt',
    'HUBH2': 'Bruttohöjning anskaff',
    'HUCRM': 'Märkning för CRM',
    'HUDAED': 'Datum för skickad EDI',
    'HUDAFS': 'Datum för Följesedel',
    'HUDAMA': 'Datum för skickat mail',
    'HUDAPL': 'Datum för plocksedel',
    'HUDATÄ': 'Ändringsdatum',
    'HUDATK': 'Kvitterad datum',
    'HUDATO': 'Orderdatum',
    'HUDATU': 'Reg.datum',
    'HUDEB': 'Tel.avis. debiteras J/',
    'HUDEL1': 'Uppdelning order 0,1,2 el 3',
    'HUDEL2': 'Uppdelning order O el L',
    'HUDELB': 'REST-order får restas',
    'HUDELL': 'Delleverans N/',
    'HUDKNR': 'Debiteringskundnummer',
    'HUDOLD': 'Visa rabatt',
    'HUDOUT': 'Dagorder J/N',
    'HUEDIF': 'EDI - fraktkostnad',
    'HUEFK': 'EDI/FAX beställning',
    'HUEGFR': 'Egen frakt',
    'HUEJLA': 'Påverka lagersaldo N/',
    'HUEJSÄ': 'Sändes ej',
    'HUEJSP': 'Ej spec. faktura',
    'HUERA': 'Efterhandsrabatt',
    'HUERA2': 'E-rabatt anskaffn',
    'HUERAB': 'Efterhandsrabatt %',
    'HUETO1': 'Ant. etikett ord. plan 1',
    'HUETO2': 'Urspr.orderns lev.fili',
    'HUETR1': 'Ant. etikett rest. plan 1',
    'HUETR2': 'Ant. etikett rest plan 2',
    'HUETS1': 'Etikettskrivare plan 1',
    'HUETS2': 'Etikettskrivare plan 2',
    'HUEX01': 'Antal kopior',
    'HUEX02': 'Antal kopior',
    'HUEX03': 'Antal kopior',
    'HUEX04': 'Antal kopior',
    'HUEX05': 'Antal kopior',
    'HUEX06': 'Antal kopior',
    'HUEX07': 'Antal kopior',
    'HUEXPA': 'Expeditionsavgift J/',
    'HUFAAD': 'Faktureringsadress',
    'HUFAKB': 'Administrationsavg. ex moms',
    'HUFAKO': 'Faktura/Kontant  F/K',
    'HUFAKP': 'Faktureringsperiod',
    'HUFAKS': 'Samlingsfakturering',
    'HUFAKU': 'Fakturautskrift E=Edi/P=EpostJ=skrivare',
    'HUFAUT': 'Används ej!',
    'HUFAVB': 'Faktureringsavg ex moms',
    'HUFAVD': 'Försäljningsavd',
    'HUFAVG': 'Fakturaavgiftkod',
    'HUFAX': 'Telefaxnummer',
    'HUFAXK': 'Fax skrivs',
    'HUFDAT': 'Fakturadatum',
    'HUFEJL': 'Fakturerad, ej levererad',
    'HUFFTG': 'Fakturaföretag',
    'HUFILI': 'Filial',
    'HUFINA': 'Finansinstitut kortbetaln.',
    'HUFNR': 'Fakturanummer',
    'HUFÖRE': 'Företag',
    'HUFRAB': 'Faxrabatt',
    'HUFRID': 'Transportföretag',
    'HUFRKR': 'Fraktkundnummer',
    'HUFRLS': 'Fraktsätt 4 pos',
    'HUFRSN': 'Fraktsedelsnummer',
    'HUFSÄT': 'Försäljningssätt',
    'HUFTID': 'Kvittensdatum',
    'HUFUTS': 'Order hämtad',
    'HUFXDA': 'Faxdatum',
    'HUFXKL': 'Faxtidpunkt',
    'HUGODS': 'Godsmärke',
    'HUGOKV': 'Godk.ändr. - kvalite',
    'HUHAMN': 'Hamnavgift Skaff/Direkt',
    'HUID': 'Orderid',
    'HUINET': 'Internetorder',
    'HUINFI': 'Filial inköp',
    'HUINK': 'Utskrift best.erkännande',
    'HUINLP': 'Rekv.löpnr inköp',
    'HUINNR': 'Rekv.nr inköp',
    'HUINTF': 'Säljarens filial',
    'HUINTO': 'Internorder - ordernr',
    'HUIREF': 'Inköpsreferens',
    'HUITAV': 'Transportavg. Skaff/Direkt',
    'HUKAMP': 'Kampanj',
    'HUKBEL': 'Kostbelopp kr',
    'HUKDAT': 'Datum för status 08, 09',
    'HUKKON': 'Kontomall',
    'HUKLAS': 'Kundklass',
    'HUKLCS': 'Klockleveransrad skapad J',
    'HUKLOC': 'Tid',
    'HUKLUB': 'Klubbmedlem',
    'HUKNR': 'Kundnummer',
    'HUKOD': 'Statuskod 0/9',
    'HUKODA': 'Kontaktdatum',
    'HUKON': 'Kontantkod',
    'HUKONR': 'Kunds ordernummer',
    'HUKPER': 'Kontaktperson',
    'HUKRA': 'Kassarab kr ex moms',
    'HUKRAB': 'Kassarabatt',
    'HUKRAM': 'Kassarabatt ink moms',
    'HUKSÄL': 'Bet.mott. säljare',
    'HUKSNR': 'Kassanummer',
    'HUKURS': 'Valutakurs',
    'HUKVÄR': 'O-värde inkl moms kontant',
    'HUKVBE': 'Kvittobelopp',
    'HUKVDA': 'Kvittodatum',
    'HUKVKD': 'Kvittenskod J/N',
    'HUKVNR': 'Kvittonummer',
    'HUKVR': 'Best.rab kvar ex moms',
    'HUKVRM': 'Best.rab. kvar inkl moms',
    'HULADK': 'Kod för utsk L/A/D',
    'HULAST': 'Lastlista utskriven J/',
    'HULBEK': 'Leveransbekr. EDI J/N',
    'HULDAG': 'Leveransdag',
    'HULEV': 'Leverantörsnr',
    'HULEVO': 'Leveransområde',
    'HULEVV': 'Leveransvillkor',
    'HULFAX': 'Lev. faxnr',
    'HULFKO': 'Lev.faktura kontrollerad J/',
    'HULKLO': 'Leveranstidpunkt',
    'HULMAI': 'Leverantörens Mailadress',
    'HULNOT': 'Lagernotering',
    'HULSÄT': 'Leveranssätt',
    'HULSTD': 'Log.modul L=Lastad',
    'HULTID': 'Önskad leveranstid',
    'HULVEK': 'Leveransvecka',
    'HULVKL': 'Används ej!',
    'HUMAIL': 'Mailadress',
    'HUMAKK': 'Makuleringskod',
    'HUMAUT': 'Utskrift mail',
    'HUMEDI': 'Mottagen via EDI tillåtna värden J el. Blank',
    'HUMFAK': 'Momsbel. för återutskrift',
    'HUMFRI': 'Momsfritt J/',
    'HUMKOD': 'Apris inkl moms',
    'HUMOJN': 'Moms utsk. order J/N',
    'HUMOMK': 'Används ej!',
    'HUMOMM': 'Momsbel. ink moms',
    'HUMOMS': 'Momsbel. ink moms',
    'HUMOTT': 'Mottagare',
    'HUMVÄR': 'O-värde inkl moms faktur',
    'HUNAMN': 'Arbetsplats Namn',
    'HUOANT': 'Antal orderbekräftelser',
    'HUOBJE': 'Objektnummer',
    'HUOBJN': 'Objektnr',
    'HUODAT': 'Orderbekräftelsedatum senaste',
    'HUOEED': 'Ordererkännande EDI  J/N/U/',
    'HUOFOR': 'Offert/Order J/N/',
    'HUÖLEV': 'Önskad levtid leverantör',
    'HUOMAK': 'Makuleringsorsak',
    'HUOMOT': 'Order mott. via Fax/Edi/Tel tillåtna värden F/E/T',
    'HUORAB': 'Ordervärdesrabatt',
    'HUÖRAM': 'Öresavr. ink moms',
    'HUÖRAV': 'Öresavr. exk moms',
    'HUORD': 'Utskrift ordererkännande',
    'HUÖRET': 'Öresavr kontant',
    'HUORN9': 'Ordernummer 7+2 pos',
    'HUOSOR': 'Sorteringsordn. fråga order',
    'HUOSTS': 'Orderuppdateringsstatus',
    'HUOTG': 'TG% på kostpris',
    'HUOTYP': 'Ordertyp',
    'HUOVÄR': 'Ordervärde exkl moms',
    'HUOVR': 'Orderv/Kassarab ex moms',
    'HUOVRI': 'Övrigt Skaff/Direkt',
    'HUOVRM': 'Orderv/Kassarab inkl moms',
    'HUPADR': 'Postadress',
    'HUPBKD': 'Påbörjad order(Utleverans)',
    'HUPGMK': 'Programkod 1=rest 2=kvit',
    'HUPKOD': 'Prissätt order/följ',
    'HUPLDA': 'Plockad datum',
    'HUPLDE': 'Plocksedel delas N/',
    'HUPLKD': 'Plan.rab.kod',
    'HUPLOC': 'Utplockare',
    'HUPLR': 'Planeringsrab ex moms',
    'HUPLRB': 'Planeringsrabatt',
    'HUPLRM': 'Planeringsrab inkl moms',
    'HUPLTI': 'Plockad tid',
    'HUPLUT': 'Packsedel utskriven J/',
    'HUPNR': 'Postnummer',
    'HUPRAO': 'Skrivare Arbetsorder',
    'HUPRDB': 'Används ej',
    'HUPRDF': 'Används ej',
    'HUPRDT': 'Används ej',
    'HUPRIO': 'Prioritering (Utleverans)',
    'HUPRIP': 'Prioritering plockning',
    'HUPRLI': 'Prislista',
    'HUPROJ': 'Projektnummer',
    'HUPROL': 'Prissättning lev.datum J/',
    'HUPRSD': 'Prissättn.datum',
    'HUPRSS': 'Prissättare',
    'HUPRT1': 'Utkö',
    'HUPRT2': 'Utkö',
    'HUPRT3': 'Utkö',
    'HURADI': 'Radio-order  R/',
    'HURAVB': 'Returavgift ex moms',
    'HURAVG': 'Returavgift %',
    'HURAVM': 'Returavgift ink moms',
    'HURDAT': 'Restorderdatum',
    'HUREKL': 'Reklamation finns J/',
    'HUREKV': 'Referens',
    'HURETU': 'Log.mod.-Retur',
    'HURKNR': 'Rabattkundnr',
    'HURKOD': 'Restorder R',
    'HUSAFS': 'Samlingsföljesedel  J=Saml FS  U=Utskriven',
    'HUSÄLJ': 'Säljarenummer',
    'HUSJFA': 'Självfaktura J/',
    'HUSKFR': 'I WRFHU - för skapa frakt',
    'HUSKIN': 'Skapa internorder J/',
    'HUSKSO': 'Skapa suborder',
    'HUSLEX': 'Sälj ex. utskriven    /1',
    'HUSLOR': 'Serviceorder/Låsorder  01=Arbetsorder Lås 02=Servi',
    'HUSMS': 'SMS J/',
    'HUSORT': 'Sortering fråga faktura 1/0',
    'HUSPAC': 'Utskr. via TRO860',
    'HUSPÄR': 'Spärrkod för kreditbevakning K=Kreditbrev F=Förfal',
    'HUSPOR': 'Spärr av order(Utl)',
    'HUSPRO': 'Spärr I-net/EDI I/E/',
    'HUSRAD': 'Senast använda radnummer',
    'HUSREK': 'Skapa rekv. J/',
    'HUSTAT': 'Registreringsstatus V = Vilande       = Ej påbörja',
    'HUSTYP': 'Statuskod i order',
    'HUSUBN': 'Senast använda subnummer',
    'HUSUTL': 'Senaste utlev.datum',
    'HUTAVB': 'Transportavg. ex moms',
    'HUTAVG': 'Transportkod',
    'HUTAVM': 'Transportavgift ink moms',
    'HUTEL1': 'Telefonnr 1',
    'HUTEL2': 'Telefonnr 2',
    'HUTELA': 'Telefonavisering J/',
    'HUTERM': 'Kod för märkning Term.ord',
    'HUTIDÄ': 'Ändrad tid',
    'HUTIDK': 'Kvitterad tid',
    'HUTIED': 'Tid för skickad EDI',
    'HUTIFS': 'Tid för följesedel',
    'HUTILB': 'Tillbaka',
    'HUTIMA': 'Tid för skickat mail',
    'HUTIPL': 'Tid för plocksedel',
    'HUTIRE': 'Tid för registerad order',
    'HUTOSU': 'Ändra orderhuvud påverka alla suborder J/',
    'HUTOT': 'Att betala ex moms',
    'HUTOTA': 'Totalt Skaff/Direkt',
    'HUTOTM': 'Att betala ink moms',
    'HUTOUT': 'Tillv.order J/N',
    'HUTRPS': 'Kontrollerad order',
    'HUTRPT': 'Text 15 positioner',
    'HUTUAV': 'Tull Skaff/Direkt',
    'HUTULL': 'Tull  % Skaff/Direkt',
    'HUTVIK': 'Total vikt',
    'HUTVOL': 'Total volym',
    'HUUBER': 'Beställningsrab utl. bel.',
    'HUUDAT': 'Önskad Utskriftsdatum',
    'HUUEOR': 'Ord. etiketter  utskr J/',
    'HUUERE': 'Rest etiketter  utskr J/',
    'HUUKRA': 'Kassarab utländskt bel.',
    'HUUMOM': 'Utl. Momsbelopp',
    'HUUMVÄ': 'Ordervärde utl.belopp Inkl',
    'HUUOVR': 'Orderv.rab utl. bel.',
    'HUUPLR': 'Planeringsrab utl. bel.',
    'HUUPVI': 'Uppdatera vikt från rader',
    'HUURAV': 'Returavgift utl. bel.',
    'HUUSER': 'Registrerad av',
    'HUUSRÄ': 'Ändrad av',
    'HUUSRK': 'Kvitterad av',
    'HUUTAV': 'Transportavg. utländskt bel.',
    'HUUTDA': 'Utskr.datum',
    'HUUTFA': 'Utläggsfaktura J/',
    'HUUTK': 'Utskrifts kod',
    'HUUTLD': 'Utlastningsdatum',
    'HUUTNR': 'Räknare för Dublett plock',
    'HUUTOT': 'Att betala, utländskt bel.',
    'HUUTRF': 'För utsk. restade rader FAX',
    'HUUTRM': 'För utsk. restade rader MAIL',
    'HUUTST': 'Utskriftskod term.ord. B/F/T',
    'HUUVÄR': 'Ordervärde utländskt belopp',
    'HUVAL': 'Valutakod',
    'HUVFAK': 'Vidarefakturering - leverantör',
    'HUVIWE': 'Visa ej i Weben J/',
    'HUWSID': 'Terminal-id',
    'HUXBE1': 'Extra belopp',
    'HUXBE2': 'Extra belopp',
    'HUXBE3': 'Extra belopp',
    'HUXDA1': 'Extra datum',
    'HUXDA2': 'Extra datum',
    'HUXDA3': 'Extra datum',
    'HUXDA4': 'Extra datum',
    'HUXDA5': 'Extra datum',
    'HUXDA6': 'Extra datum',
    'HUXDA7': 'Extra datum',
    'HUXFL1': 'Extra fält 10 pos',
    'HUXFL2': 'Extra fält 10 pos',
    'HUXFL3': 'Extra fält 10 pos',
    'HUXFL4': 'Extra fält 10 pos',
    'HUXFL5': 'Utlasningg klocka',
    'HUXFL6': 'Filial vid deln.suborder',
    'HUXFL7': 'Extra fält 10 pos',
    'HUXFL8': 'Extra fält 10 pos',
    'HUXFL9': 'Extra fält 10 pos',
    'HUXK10': 'klarmarkerad huborder',
    'HUXKD1': 'Extra kod',
    'HUXKD2': 'Extra kod',
    'HUXKD3': 'Extra kod',
    'HUXKD4': 'Extra kod',
    'HUXKD5': 'Extra kod',
    'HUXKD6': 'Extra kod',
    'HUXKD7': 'Skicka order till 3PL',
    'HUXKD8': 'Byte säljande filial',
    'HUXKD9': 'Lev från log center',
}

export const orderFields: string[] = [
    'HUABVB',
    'HUADR1',
    'HUADR2',
    'HUAFIL',
    'HUAINK',
    'HUALOP',
    'HUANDI',
    'HUÄNDK',
    'HUANTÄ',
    'HUANV2',
    'HUANVI',
    'HUAOUT',
    'HUARB',
    'HUAVID',
    'HUÄVII',
    'HUAVIS',
    'HUAVNR',
    'HUAVTO',
    'HUBEB1',
    'HUBEB2',
    'HUBEB3',
    'HUBEBE',
    'HUBEFI',
    'HUBEID',
    'HUBEKA',
    'HUBEOR',
    'HUBER',
    'HUBERB',
    'HUBERJ',
    'HUBERM',
    'HUBET1',
    'HUBET2',
    'HUBET3',
    'HUBETR',
    'HUBETV',
    'HUBEUT',
    'HUBFT',
    'HUBH1',
    'HUBH2',
    'HUCRM',
    'HUDAED',
    'HUDAFS',
    'HUDAMA',
    'HUDAPL',
    'HUDATÄ',
    'HUDATK',
    'HUDATO',
    'HUDATU',
    'HUDEB',
    'HUDEL1',
    'HUDEL2',
    'HUDELB',
    'HUDELL',
    'HUDKNR',
    'HUDOLD',
    'HUDOUT',
    'HUEDIF',
    'HUEFK',
    'HUEGFR',
    'HUEJLA',
    'HUEJSÄ',
    'HUEJSP',
    'HUERA',
    'HUERA2',
    'HUERAB',
    'HUETO1',
    'HUETO2',
    'HUETR1',
    'HUETR2',
    'HUETS1',
    'HUETS2',
    'HUEX01',
    'HUEX02',
    'HUEX03',
    'HUEX04',
    'HUEX05',
    'HUEX06',
    'HUEX07',
    'HUEXPA',
    'HUFAAD',
    'HUFAKB',
    'HUFAKO',
    'HUFAKP',
    'HUFAKS',
    'HUFAKU',
    'HUFAUT',
    'HUFAVB',
    'HUFAVD',
    'HUFAVG',
    'HUFAX',
    'HUFAXK',
    'HUFDAT',
    'HUFEJL',
    'HUFFTG',
    'HUFILI',
    'HUFINA',
    'HUFNR',
    'HUFÖRE',
    'HUFRAB',
    'HUFRID',
    'HUFRKR',
    'HUFRLS',
    'HUFRSN',
    'HUFSÄT',
    'HUFTID',
    'HUFUTS',
    'HUFXDA',
    'HUFXKL',
    'HUGODS',
    'HUGOKV',
    'HUHAMN',
    'HUID',
    'HUINET',
    'HUINFI',
    'HUINK',
    'HUINLP',
    'HUINNR',
    'HUINTF',
    'HUINTO',
    'HUIREF',
    'HUITAV',
    'HUKAMP',
    'HUKBEL',
    'HUKDAT',
    'HUKKON',
    'HUKLAS',
    'HUKLCS',
    'HUKLOC',
    'HUKLUB',
    'HUKNR',
    'HUKOD',
    'HUKODA',
    'HUKON',
    'HUKONR',
    'HUKPER',
    'HUKRA',
    'HUKRAB',
    'HUKRAM',
    'HUKSÄL',
    'HUKSNR',
    'HUKURS',
    'HUKVÄR',
    'HUKVBE',
    'HUKVDA',
    'HUKVKD',
    'HUKVNR',
    'HUKVR',
    'HUKVRM',
    'HULADK',
    'HULAST',
    'HULBEK',
    'HULDAG',
    'HULEV',
    'HULEVO',
    'HULEVV',
    'HULFAX',
    'HULFKO',
    'HULKLO',
    'HULMAI',
    'HULNOT',
    'HULSÄT',
    'HULSTD',
    'HULTID',
    'HULVEK',
    'HULVKL',
    'HUMAIL',
    'HUMAKK',
    'HUMAUT',
    'HUMEDI',
    'HUMFAK',
    'HUMFRI',
    'HUMKOD',
    'HUMOJN',
    'HUMOMK',
    'HUMOMM',
    'HUMOMS',
    'HUMOTT',
    'HUMVÄR',
    'HUNAMN',
    'HUOANT',
    'HUOBJE',
    'HUOBJN',
    'HUODAT',
    'HUOEED',
    'HUOFOR',
    'HUÖLEV',
    'HUOMAK',
    'HUOMOT',
    'HUORAB',
    'HUÖRAM',
    'HUÖRAV',
    'HUORD',
    'HUÖRET',
    'HUORN9',
    'HUOSOR',
    'HUOSTS',
    'HUOTG',
    'HUOTYP',
    'HUOVÄR',
    'HUOVR',
    'HUOVRI',
    'HUOVRM',
    'HUPADR',
    'HUPBKD',
    'HUPGMK',
    'HUPKOD',
    'HUPLDA',
    'HUPLDE',
    'HUPLKD',
    'HUPLOC',
    'HUPLR',
    'HUPLRB',
    'HUPLRM',
    'HUPLTI',
    'HUPLUT',
    'HUPNR',
    'HUPRAO',
    'HUPRDB',
    'HUPRDF',
    'HUPRDT',
    'HUPRIO',
    'HUPRIP',
    'HUPRLI',
    'HUPROJ',
    'HUPROL',
    'HUPRSD',
    'HUPRSS',
    'HUPRT1',
    'HUPRT2',
    'HUPRT3',
    'HURADI',
    'HURAVB',
    'HURAVG',
    'HURAVM',
    'HURDAT',
    'HUREKL',
    'HUREKV',
    'HURETU',
    'HURKNR',
    'HURKOD',
    'HUSAFS',
    'HUSÄLJ',
    'HUSJFA',
    'HUSKFR',
    'HUSKIN',
    'HUSKSO',
    'HUSLEX',
    'HUSLOR',
    'HUSMS',
    'HUSORT',
    'HUSPAC',
    'HUSPÄR',
    'HUSPOR',
    'HUSPRO',
    'HUSRAD',
    'HUSREK',
    'HUSTAT',
    'HUSTYP',
    'HUSUBN',
    'HUSUTL',
    'HUTAVB',
    'HUTAVG',
    'HUTAVM',
    'HUTEL1',
    'HUTEL2',
    'HUTELA',
    'HUTERM',
    'HUTIDÄ',
    'HUTIDK',
    'HUTIED',
    'HUTIFS',
    'HUTILB',
    'HUTIMA',
    'HUTIPL',
    'HUTIRE',
    'HUTOSU',
    'HUTOT',
    'HUTOTA',
    'HUTOTM',
    'HUTOUT',
    'HUTRPS',
    'HUTRPT',
    'HUTUAV',
    'HUTULL',
    'HUTVIK',
    'HUTVOL',
    'HUUBER',
    'HUUDAT',
    'HUUEOR',
    'HUUERE',
    'HUUKRA',
    'HUUMOM',
    'HUUMVÄ',
    'HUUOVR',
    'HUUPLR',
    'HUUPVI',
    'HUURAV',
    'HUUSER',
    'HUUSRÄ',
    'HUUSRK',
    'HUUTAV',
    'HUUTDA',
    'HUUTFA',
    'HUUTK',
    'HUUTLD',
    'HUUTNR',
    'HUUTOT',
    'HUUTRF',
    'HUUTRM',
    'HUUTST',
    'HUUVÄR',
    'HUVAL',
    'HUVFAK',
    'HUVIWE',
    'HUWSID',
    'HUXBE1',
    'HUXBE2',
    'HUXBE3',
    'HUXDA1',
    'HUXDA2',
    'HUXDA3',
    'HUXDA4',
    'HUXDA5',
    'HUXDA6',
    'HUXDA7',
    'HUXFL1',
    'HUXFL2',
    'HUXFL3',
    'HUXFL4',
    'HUXFL5',
    'HUXFL6',
    'HUXFL7',
    'HUXFL8',
    'HUXFL9',
    'HUXK10',
    'HUXKD1',
    'HUXKD2',
    'HUXKD3',
    'HUXKD4',
    'HUXKD5',
    'HUXKD6',
    'HUXKD7',
    'HUXKD8',
    'HUXKD9',
]