import React, { ReactElement, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { getWindowTitle } from '../../App/variables';
import { LanguageAtom } from '../../Atoms/LanguageAtom';
import AccessDenied from '../../Components/StatusCodes/AccessDenied';
import PageNotFound from '../../Components/StatusCodes/PageNotFound';
import IPage from '../../Interfaces/IPage';
import './StartPage.scss';

interface IStartPage extends IPage {

};

const StartPage: React.FunctionComponent<IStartPage> = () => {
  const [language] = useRecoilState(LanguageAtom);

  return (
    <div className="app-page-content center">
      <h2>Välkommen till {getWindowTitle()}</h2>
    </div>
  );
};

export default StartPage;
