import ILanguage from "../Interfaces/ILanguage"

export const sv: ILanguage = {
    signingIn: "Loggar in..",
    startPage: {
        headers : { 
            welcomeText: "Välkommen till Beijer Track'n'Trace, använd sökrutan i högst upp på sidan för att söka på en order",
        }
    },
    orderPage: {
        loadingOrder: "Laddar order",
        actions: {
            viewAsPrintable: "Visa som Utskrivbar",
        },
        headers: {
            events: "Händelser",
            additonalOrders: "Övriga ordrar i leveransen",
            noOrderWithIdFound: (text: string) => `Ingen order med id't '${text}' kunde hittas.`,
        },
        event: {
            driver: 'Förare',
            route: 'Rutt',
            eventCMD: (cmd: number) => {
                switch (cmd) {
                    case 7: return 'Anlänt';
                    case 8: return 'Färdig';
                    case 35: return 'Bild';
                    default: return '';
                }
            },
            eventSType: (stype: number) => {
                switch (stype) {
                    case 0: return 'Start Depå';
                    case 1: return 'Slut Depå';
                    case 2: return 'Pickup';
                    case 3: return 'Leverans';
                    default: return '';
                }
            },
        },
        image: {
            invalidImage: 'Ogiltlig bild',
            imageTypeName: (type: string) => {
                switch (type) {
                    case "Job Signature": return 'Signatur';
                    case "Job Photo": return 'Leverans';
                    default: return '';
                }
            },
        },
    },
    orderNotFoundPage: {
        noOrderWithIdFound: (text: string) => `Ingen order med id't '${text}' kunde hittas.`,
    }
}