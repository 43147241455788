import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { getNowDateTimeString } from '../../Services/DateService';
import { Order } from '../../Types/ResponseTypes/Order';
import { deliveryMethodMap } from '../../Constants/PrintValueMaps';
import { getWindowTitle } from '../../App/variables';


interface IPrintPackingSlip {
  orders: Order[],
};

const css = `
.app-print-packingslip-orders {
    size: 17.78cm 23.495cm;
    margin: 0 16mm 0 16mm;
}

.app-print-packingslip-order {
  page-break-after: always;
  break-after: always;
}

.app-print-packingslip-order p,
.app-print-packingslip-order span {
  font-size: 12px;
}

.app-print-packingslip-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 10px;
  border-bottom: 1px dashed black;
}
.app-print-packingslip-info-section {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.app-print-packingslip-addr {
  width: 100%;
  margin: 10px 10px;
}

.app-print-packingslip-addr p { margin: 0; }

.app-print-packingslip-meta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 10px;
  border-bottom: 1px dashed black;
}

.app-print-packingslip-articles table {
  width: 100%;
  padding: 5px 1cm;
}

.app-print-packingslip-articles table thead th {
  
  text-align: left;
  font-size: 12px;
}

.app-print-packingslip-articles table tbody th {
  font-weight: 400;
  vertical-align: top;
  text-align: left;
  padding: 7px 0px;
  font-size: 12px;
}

.app-print-packingslip-articles table tbody th p {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 12px;
}

.app-print-packingslip-label { font-weight: 600; margin-right: 10px; }

.app-print-packingslip-dashed-divider { 
  grid-column: 1 / 3;
  margin: 5px 20px 5px 0px;
  padding: 10px 00px;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.app-print-packingslip-value.missing {
  color: orange;
}
`;

const renderPackingSlip = (order: Order): ReactElement => (
  <div className="app-print-packingslip-order">
    <style>{css}</style>

    <div className="app-print-packingslip-header">
      <div>
        <h3>{getWindowTitle()}</h3>

        <span className="app-print-packingslip-label">Arbetsplatsnummer</span>
        <br />
        <span className="app-print-packingslip-value">{order.huarb}</span>
      </div>
      <div>
        <h4>Plocksedel   {order.huföre} - {order.huorN9.toString().substring(0, 7)}-{order.huorN9.toString().substring(7, 9)}</h4>
        <div className="app-print-packingslip-info-section">
          <span className="app-print-packingslip-label">Orderdatum</span>
          <span className="app-print-packingslip-value">{order.hudato}</span>
          <span className="app-print-packingslip-label">Utskriftsdatum</span>
          <span className="app-print-packingslip-value">{order.huudat}</span>
          <span className="app-print-packingslip-label">Kundnr</span>
          <span className="app-print-packingslip-value">{order.huknr}</span>
        </div>
      </div>
    </div>
    <div className="app-print-packingslip-addr">

      <p className="app-print-packingslip-label">Leveransadress</p>
      <p className="app-print-packingslip-value">{order.huadR1}</p>
      <p className="app-print-packingslip-value">{order.huadR2}</p>

      <p className="app-print-packingslip-value">{order.hupnr}, {order.hupadr}</p>
    </div>
    <div className="app-print-packingslip-meta">
      <div>



        <div className="app-print-packingslip-info-section">
          <span className="app-print-packingslip-label">Märke/ordnr</span>
          <span className="app-print-packingslip-value">{order.hukonr}</span>
          <span className="app-print-packingslip-label">Kund Ref</span>
          <span className="app-print-packingslip-value">{order.hurekv}</span>
          <span className="app-print-packingslip-label">Kund Mail</span>
          <span className="app-print-packingslip-value">{order.humail}</span>

          <span className="app-print-packingslip-label">Telefon{order.huteL2 && order.huteL1 != order.huteL2 ? ' (1)' : ''}</span>
          <span className="app-print-packingslip-value">{order.huteL1}</span>
          {
            order.huteL2 && order.huteL1 != order.huteL2 ? (
              <>
                <span className="app-print-packingslip-label">Telefon (2)</span>
                <span className="app-print-packingslip-value">{order.huteL2}</span>
              </>
            ) : null
          }

          
          <span className="app-print-packingslip-label">Mottagare</span>
          <span className="app-print-packingslip-value">{order.humott}</span>
        </div>
      </div>
      <div>

        <div className="app-print-packingslip-info-section">
          <span className="app-print-packingslip-label">Vår ref</span>
          <span className="app-print-packingslip-value missing"> { order.husälj } </span>
          <span className="app-print-packingslip-label">Telefon</span>
          <span className="app-print-packingslip-value missing"> PLACEHOLDER_SÄLJ </span>
          <span className="app-print-packingslip-label">Lev.tid</span>
          <span className="app-print-packingslip-value">{order.hultid} V{order.hulvek} {order.huldag} Kl {order.hulklo}</span>
          <span className="app-print-packingslip-label">Lev.sätt.</span>
          <span className="app-print-packingslip-value">{ deliveryMethodMap[order.hulsät] ?? '-'}</span>


          <span className="app-print-packingslip-label">Lev.anv (1)</span>
          <span className="app-print-packingslip-value">{order.huanvi ? order.huanvi : '-'}</span>
          <span className="app-print-packingslip-label">Lev.anv (2)</span>
          <span className="app-print-packingslip-value">{order.huanV2 ? order.huanV2 : '-'}</span>
        </div>
      </div>
    </div>
    <div className="app-print-packingslip-articles">
      <table>
        <thead>
          <tr>
            <th>T</th>
            <th>Rad</th>
            <th>Artikelnr</th>
            <th>Beställt</th>
            <th>Enh</th>
            <th>Levererat</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          {
            order.orderRows ? order.orderRows.map((_, i) => (
              <tr key={`${order.huorN9}-or-${i}`}>
                <td></td>
                <td>{_.raradn}</td>
                <td>
                  <p>{_.raart ? _.raart : '-'}</p>
                  <p>{_.rabeN1 ? _.rabeN1 : '-'}</p>
                  <p>{_.rabeN2 ? _.rabeN2 : '-'}</p>
                  <p>{_.rahyll ? _.rahyll : order.relatedArticles[_.raart]?.fihyll ?? ' - '}</p>
                </td>
                <td>{_.raanta}</td>
                <td>{_.raenh}</td>
                <td>____________________</td>
                <td>{order.relatedArticles[_.raart]?.fisald}</td>
              </tr>
            )) : null
          }
        </tbody>
      </table>
      <span className="app-print-packingslip-value">Totalvikt order: {order.hutvik} kg</span>
    </div>

    <br />
    <br />

    <div className="app-print-packingslip-footer">

    </div>


  </div>
)

const PrintPackingSlip: React.FC<IPrintPackingSlip> = ({ orders }): ReactElement => {
  return (
    <div className="app-print-packingslip-orders">

      {
        orders.map(_ => renderPackingSlip(_))
      }

    </div>

  )
};

export default PrintPackingSlip;
