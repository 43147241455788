import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spin } from 'antd';
import { Account } from 'msal';
import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { LanguageAtom } from '../Atoms/LanguageAtom';
import { SelectedFilialAtom } from '../Atoms/SelectedFilialAtom';
import AppHeader from '../Components/AppHeader/AppHeader';
import { Languages } from '../Lang/languages';
import AuthService from '../Services/AuthService';
import { ApplicationUser } from '../Types/ResponseTypes/ApplicationUser';
import './App.scss';
import { getLinks } from './links';
import { AppRoute, routes } from './Routes';
import { getWindowTitle } from './variables';


export interface IApp {
}

const App: React.FC<IApp> = ({ }): ReactElement => {
  const [language, setLanguage] = useRecoilState(LanguageAtom);
  const [filial, setFilial] = useRecoilState(SelectedFilialAtom);

  useEffect(() => {
    const languagePreferens = localStorage.getItem('language-preferens');
    if (languagePreferens) setLanguage(Languages[languagePreferens]);
  }, []);

  const setLanguageSwedish = () => {
    setLanguage(Languages['sv']);
    localStorage.setItem('language-preferens', 'sv');
  };
  const setLanguageEnglish = () => {
    setLanguage(Languages['en']);
    localStorage.setItem('language-preferens', 'en');
  };


  const [userAccount, setUserAccount] = useState<Account>();
  const [user, setUser] = useState<ApplicationUser>();

   useEffect(() => {
    const acc = AuthService.getCurrent().login();
    if (acc) setUserAccount(acc);
  }, []);



  if (!userAccount) {
    return (
      <div id="signing-in-wrapper">
        <div id="signing-in-logo"><span>{getWindowTitle()}</span></div>
        <Spin tip="Signing in.." size="large" />
      </div>
    )
  }

  return (
    <div id="app">
      <BrowserRouter>
        <AppHeader navigationLinks={getLinks(filial)} account={userAccount} />
        <div id="app-page-wrapper">
          <Routes>
            {
              routes.map((route: AppRoute, index: number): ReactElement => {
                const key = `route-${index}`;
                return (
                  <Route key={key} path={route.path} element={<route.component />} />
                );
              })
            }
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;