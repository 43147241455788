import React from 'react';
import DefaultPage from '../Pages/DefaultPage/DefaultPage';
import FilialerPage from '../Pages/FilialerPage/FilialerPage';
import FilialPage from '../Pages/FilialPage/FilialPage';
import OrderNotFoundPage from '../Pages/OrderNotFoundPage/OrderNotFoundPage';
import StartPage from '../Pages/StartPage/StartPage';

export interface AppRoute {
  path: string,
  component: React.FC,
};

export const routes: AppRoute[] = [
  {
    path: 'not-found',
    component: OrderNotFoundPage,
  },

  {
    path: 'filialer',
    component: FilialerPage,
  },
  {
    path: 'filialer/:filialId/*',
    component: FilialPage,
  },
  {
    path: '/',
    component: StartPage,
  },
  {
    path: '*',
    component: DefaultPage,
  }

];
